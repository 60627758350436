<template>
  <Sounds :isClean="true"/>
</template>

<script>
import Sounds from '@/components/Sounds.vue'
  export default {
    name: 'Clean',
    components: { Sounds },
  }
</script>
