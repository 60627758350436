<template>
  <v-app style="background: black;">
    <NavBar/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'App',
  metaInfo: {
    // Children can override the title.
    title: 'The Essential Scary Terry',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    // titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'All the essential Scary Terry soundbites.'},
      // OpenGraph data (Most widely used)
      {property: 'og:title', content: 'The Essential Scary Terry'},
      {property: 'og:site_name', content: 'The Essential Scary Terry'},
      // The list of types is available here: http://ogp.me/#types
      {property: 'og:type', content: 'website'},
      // Should the the same as your canonical link, see below.
      {property: 'og:url', content: 'https://www.essentialscaryterry.com/'},
      {property: 'og:image', content: 'https://www.essentialscaryterry.com/img/Icon-60@3x.3e5682a8.png'},
      // Often the same as your meta description, but not always.
      {property: 'og:description', content: 'All the essential Scary Terry soundbites.'},
      // Google / Schema.org markup:
      {itemprop: 'name', content: 'The Essential Scary Terry'},
      {itemprop: 'description', content: 'All the essential Scary Terry soundbites.'},
      {itemprop: 'image', content: 'https://www.essentialscaryterry.com/img/Icon-60@3x.3e5682a8.png'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.essentialscaryterry.com/'}
    ]
  },
  components: {
    NavBar
  },
  data: () => ({
    //
  }),
};
</script>
