<template>
    <nav>
        <v-app-bar app dark elevation="8">
            <v-app-bar-nav-icon disabled>
                <v-img contain width="45" src="@/assets/images/Icon-60@3x.png"/>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="appBarTitleTextWithAutoShrink">
                <span class="font-weight-black">THE</span><span class="font-weight-light">ESSENTIAL</span><span class="font-weight-black">SCARYTERRY</span>
            </v-toolbar-title>
            <v-btn
                x-small 
                text 
                @click="showAboutDialog">
                v{{ projectVersion }}
            </v-btn>
            <v-spacer v-for="num in Array.from(Array(200).keys())" :key=num></v-spacer> <!-- Complete hack to inset the icon from the right edge of the app bar -->
            <v-btn large icon dark @click="toggleCleanMode">
                <v-img v-if="isCleanMode == false" contain width="45" src="@/assets/images/advisory.png"/>
                <div v-else>Clean</div>
            </v-btn>
            <v-spacer></v-spacer>
        </v-app-bar>
        <div class="text-center">
            <v-dialog
                v-model="isAboutDialogShowing"
                width="500"
                >
                <v-card dark elevation="8">
                    <v-card-title>
                        <span class="font-weight-black">THE</span><span class="font-weight-light">ESSENTIAL</span><span class="font-weight-black">SCARYTERRY</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <p>All the essential Scary Terry soundbites.</p>
                        <p>Totally NOT official.</p>
                        <p>Go support Adult Swim's 'Rick and Morty' show <a href="https://www.adultswim.com/videos/rick-and-morty/" target="_blank">here</a>.</p>
                        <p style="max-width: 85px;"><a href="http://www.mjchp.com/" style="text-decoration:none" target="_blank"><MHPSLogo :abbreviated="true" :minFontSize="20" :maxFontSize="20" /></a></p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn
                            x-small
                            text
                            disabled
                            >
                        <p>v{{ projectVersion }}</p>
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            color="pink"
                            text
                            @click="hideAboutDialog"
                        >
                        Dismiss
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </nav>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import MHPSLogo from './logos/MHPSLogo.vue'

export default {
    name: 'NavBar',
    components: { MHPSLogo },
    setup(props, { root }) {
        const cleanModePath = '/clean'
        const isCleanMode = ref(root.$route.path === cleanModePath)
        const isCleanModeFound = ref(false)
        const isAboutDialogShowing = ref(false)
        const projectVersion = ref(process.env.PACKAGE_VERSION || '0')

        // Navigation
        const navigate = (newPath) => {
            if (root.$route.path !== newPath) {
                root.$router.push(newPath)
            }
        }

        // Clean Mode "Secret Found" audio player
        const cleanModeFoundPlayer = new Audio(require('@/assets/sounds/other/SecretFound.mp3'))

        const toggleCleanMode = () => {
            isCleanMode.value = !isCleanMode.value

            // Check if 'secret' clean mode was found
            if (isCleanModeFound.value == false) {
                isCleanModeFound.value = true
                // If toggling to clean mode, play the 'secret found' sound effect
                if (isCleanMode.value == true) {
                    cleanModeFoundPlayer?.play()
                }
            }

            if (isCleanMode.value == true) {
                navigate(cleanModePath)
            } else {
                navigate('/')
            }
        }

        const showAboutDialog = () => {
            isAboutDialogShowing.value = true
        }

        const hideAboutDialog = () => {
            isAboutDialogShowing.value = false
        }

        onMounted(() => {
            // Prime the audio clip
            cleanModeFoundPlayer?.load()
        })

        return {
            isAboutDialogShowing,
            isCleanMode,
            projectVersion,
            toggleCleanMode,
            navigate,
            showAboutDialog,
            hideAboutDialog
        }
    }
}
</script>

<style scoped>
    .appBarTitleTextWithAutoShrink {
        font-size: min(4.0vw, 1.5rem);
    }
</style>