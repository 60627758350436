<template>
  <Sounds/>
</template>

<script>
import Sounds from '@/components/Sounds.vue'
  export default {
    name: 'Home',
    components: { Sounds },
  }
</script>
