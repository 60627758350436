<template>
    <v-container fluid fill-height grid-list-md class="with-background" style="max-width: 1920px;">
        <v-row wrap dense justify-space-around>
            <v-col 
            v-for="(soundItemScene) in soundItemScenes"
            :key="soundItemScene.id"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            >
                <v-card dark height="100%">
                    <v-responsive>
                        <v-parallax height="250" :src="soundItemScene.asset"></v-parallax>
                    <v-app-bar dark dense>
                        <v-toolbar-title>{{ soundItemScene.title }}</v-toolbar-title>
                    </v-app-bar>
                    <v-container fluid grid-list-md>
                        <v-row wrap dense justify-space-around>
                        <v-col 
                        v-for="(soundItem) in soundItemsForScene(soundItemScene.id)" 
                        :key="soundItem.title"
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        >
                        <v-card 
                        elevation="8"
                        height="100%"
                        dark
                        :ripple="false"
                        :loading="soundItem.isPlaying"
                        :style="soundItem.isPlaying ? 'border: 2px solid rgba(237, 0, 97, 1);' : 'border: 2px solid transparent;'"
                        @click="playSoundItem(soundItem)"
                        class="soundCard">
                            <!-- placeholder progress bar to avoid altering card height -->
                            <v-progress-linear v-if="!soundItem.isPlaying" color="transparent" indeterminate></v-progress-linear>
                            <template slot="progress">
                                <!-- visible progress bar (when playing sound) -->
                                <v-progress-linear color="pink" indeterminate></v-progress-linear>
                            </template>
                            <div style="height: 99%;" class="d-flex justify-start align-center">
                                <v-icon color="pink" x-large>play_arrow</v-icon>
                                <v-card-text class="pa-1">{{ soundItem.title }}</v-card-text>
                            </div>
                        </v-card>
                        </v-col>
                    </v-row>
                    </v-container>
                    </v-responsive>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { onMounted, reactive } from '@vue/composition-api'

export default {
    props: {
        isClean: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const soundItemScenes = reactive([
            {   
                id: 1,
                title: 'Welcome To Your Nightmare',
                asset: 'Welcome_To_Your_Nightmare.jpeg'
            },
            {
                id: 2,
                title: 'Scary Street',
                asset: 'Scary_Street.jpeg'
            },
            {
                id: 3,
                title: 'The Scary Residence',
                asset: 'The_Scary_Residence.jpeg'
            },
            {
                id: 4,
                title: `Scary Terry's Nightmare`,
                asset: 'Scary_Terrys_Nightmare.jpeg'
            },
            {
                id: 5,
                title: `The Scary Bedroom`,
                asset: 'The_Scary_Bedroom.jpeg'
            },
            {
                id: 6,
                title: `The Scary Dining Room`,
                asset: 'The_Scary_Dining_Room.jpeg'
            },
            {
                id: 7,
                title: `Dreams Revisited`,
                asset: 'Dreams_Revisited.jpeg'
            }
        ].map(scene => {
            return { ...scene, asset: require(`@/assets/scenes/${scene.asset}`)}
        }))
        const soundItems = reactive([{
            title: `"Aww.. Hey It's You Guys!"`,
            asset: "AwwHeyItsYouGuys.mp3",
            scene: 5
        },
        {
            title: `"Aww... Bitch.."`,
            asset: "AwwwBitch.mp3",
            scene: 4
        },
        {
            title: "Bad Dream Groan",
            asset: "BadDreamGroan.mp3",
            scene: 3
        },
        {
            title: `"Buckle Up, Bitch!"`,
            asset: "BuckleUpBitch.mp3",
            scene: 2
        },
        {
            title: "Frustrated Grunt",
            asset: "FrustratedGrunt.mp3",
            scene: 2
        },
        {
            title: `"Get Off My Back, Bitch!"`,
            asset: "GetOffMyBackBitch.mp3",
            scene: 3
        },
        {
            title: `"I Always Hated That Song!"`,
            asset: "IAlwaysHatedThatSong.mp3",
            scene: 7
        },
        {
            title: `"I Don't Know What To Say."`,
            asset: "IDontKnowWhatToSay.mp3",
            scene: 4
        },
        {
            title: `"I Don't Wanna Talk About It."`,
            asset: "IDontWannaTalkAboutIt.mp3",
            scene: 3
        },
        {
            title: `"If You Guys Ever Need Anything Just Say The Word."`,
            asset: "IfYouGuysEverNeedAnythingJustSayTheWord.mp3",
            scene: 6
        },
        {
            title: `"I Know.. I Know."`,
            asset: "IKnowIKnow.mp3",
            scene: 3
        },
        {
            title: `"I Love You Melissa."`,
            asset: "ILoveYouMelissa.mp3",
            scene: 3
        },
        {
            title: `"I'm Scary Terry!"`,
            asset: "ImScaryTerry.mp3",
            scene: 1
        },
        {
            title: `"I Shouldn't Take My Anger Out On You Or Scary Brandon."`,
            asset: "IShouldntTakeMyAngerOutOnYouOrScaryBrandon.mp3",
            scene: 3
        },
        {
            title: `"Nothing But Fear From Here On Out, Bitch!"`,
            asset: "NothingButFearFromHereOnOutBitch.mp3",
            scene: 1
        },
        {
            title: `"Oh No! I'm Late To Class, Bitch!"`,
            asset: "OhNoImLateToClassBitch.mp3",
            scene: 4
        },
        {
            title: `"Oh No! I'm Not Wearing Any Pants!"`,
            asset: "OhNoImNotWearingAnyPants.mp3",
            scene: 4
        },
        {
            title: `"Oh.. Umm.. Bitch.."`,
            asset: "OhUmBitch.mp3",
            scene: 4
        },
        {
            title: `"Sex Is Sacred!"`,
            asset: "SexIsSacred.mp3",
            scene: 7
        },
        {
            title: `"These Halves Don't Belong Together, Bitch!"`,
            asset: "TheseHalvesDontBelongTogetherBitch.mp3",
            scene: 7
        },
        {
            title: `"This Is Because You Don't Give Morty Smith Good Grades, Bitch!"`,
            asset: "ThisIsBecauseYouDontGiveMortySmithGoodGradesBitch.mp3",
            scene: 7
        },
        {
            title: `"Welcome To Your Nightmare, Bitch!"`,
            asset: "WelcomeToYourNightmareBitch.mp3",
            scene: 1
        },
        {
            title: "Yawn",
            asset: "Yawn.mp3",
            scene: 2
        },
        {
            title: `"You CAN Run But You Can't Hide.."`,
            asset: "YouCANRunButYouCantHide.mp3",
            scene: 2
        },
        {
            title: `"You Can Run But You Can't Hide, Bitch!"`,
            asset: "YouCanRunButYouCantHideBitch.mp3",
            scene: 1
        },
        {
            title: `"You Can Run But You Can't Hide, Bitch!" [2]`,
            asset: "YouCanRunButYouCantHideBitch2.mp3",
            scene: 2
        }].map(soundItem => {
            const player = soundItem?.asset ? new Audio(require('@/assets/sounds/' + soundItem.asset)) : undefined
            // Setup new soundItem object
            const newSoundItem = {
                ...soundItem,
                player,
                isPlaying: false
            }
            // Setup event listeners to update play state for new sound item
            player?.addEventListener('ended', () => {
                // console.log(`${soundItem.title} ended.`)
                newSoundItem.isPlaying = false
            })
            player?.addEventListener('play', () => {
                // console.log(`${soundItem.title} is playing.`)
                newSoundItem.isPlaying = true
            })
            player?.addEventListener('pause', () => {
                // console.log(`${soundItem.title} is paused.`)
                newSoundItem.isPlaying = false
            })
            return newSoundItem
        }))
        const soundItemsClean = reactive([{
            title: `"Aww.. Hey It's You Guys!"`,
            asset: "AwwHeyItsYouGuys.mp3",
            scene: 5
        },
        {
            title: `"Aww... B****.."`,
            asset: "AwwwB____.mp3",
            scene: 4
        },
        {
            title: "Bad Dream Groan",
            asset: "BadDreamGroan.mp3",
            scene: 3
        },
        {
            title: `"Buckle Up, B****!"`,
            asset: "BuckleUpB____.mp3",
            scene: 2
        },
        {
            title: "Frustrated Grunt",
            asset: "FrustratedGrunt.mp3",
            scene: 2
        },
        {
            title: `"Get Off My Back, B****!"`,
            asset: "GetOffMyBackB____.mp3",
            scene: 3
        },
        {
            title: `"I Always Hated That Song!"`,
            asset: "IAlwaysHatedThatSong.mp3",
            scene: 7
        },
        {
            title: `"I Don't Know What To Say."`,
            asset: "IDontKnowWhatToSay.mp3",
            scene: 4
        },
        {
            title: `"I Don't Wanna Talk About It."`,
            asset: "IDontWannaTalkAboutIt.mp3",
            scene: 3
        },
        {
            title: `"If You Guys Ever Need Anything Just Say The Word."`,
            asset: "IfYouGuysEverNeedAnythingJustSayTheWord.mp3",
            scene: 6
        },
        {
            title: `"I Know.. I Know."`,
            asset: "IKnowIKnow.mp3",
            scene: 3
        },
        {
            title: `"I Love You Melissa."`,
            asset: "ILoveYouMelissa.mp3",
            scene: 3
        },
        {
            title: `"I'm Scary Terry!"`,
            asset: "ImScaryTerry.mp3",
            scene: 1
        },
        {
            title: `"I Shouldn't Take My Anger Out On You Or Scary Brandon."`,
            asset: "IShouldntTakeMyAngerOutOnYouOrScaryBrandon.mp3",
            scene: 3
        },
        {
            title: `"Nothing But Fear From Here On Out, B****!"`,
            asset: "NothingButFearFromHereOnOutB____.mp3",
            scene: 1
        },
        {
            title: `"Oh No! I'm Late To Class, B****!"`,
            asset: "OhNoImLateToClassB____.mp3",
            scene: 4
        },
        {
            title: `"Oh No! I'm Not Wearing Any Pants!"`,
            asset: "OhNoImNotWearingAnyPants.mp3",
            scene: 4
        },
        {
            title: `"Oh.. Umm.. B****.."`,
            asset: "OhUmB____.mp3",
            scene: 4
        },
        {
            title: `"S** Is Sacred!"`,
            asset: "S__IsSacred.mp3",
            scene: 7
        },
        {
            title: `"These Halves Don't Belong Together, B****!"`,
            asset: "TheseHalvesDontBelongTogetherB____.mp3",
            scene: 7
        },
        {
            title: `"This Is Because You Don't Give Morty Smith Good Grades, B****!"`,
            asset: "ThisIsBecauseYouDontGiveMortySmithGoodGradesB____.mp3",
            scene: 7
        },
        {
            title: `"Welcome To Your Nightmare, B****!"`,
            asset: "WelcomeToYourNightmareB____.mp3",
            scene: 1
        },
        {
            title: "Yawn",
            asset: "Yawn.mp3",
            scene: 2
        },
        {
            title: `"You CAN Run But You Can't Hide.."`,
            asset: "YouCANRunButYouCantHide.mp3",
            scene: 2
        },
        {
            title: `"You Can Run But You Can't Hide, B****!"`,
            asset: "YouCanRunButYouCantHideB____.mp3",
            scene: 1
        },
        {
            title: `"You Can Run But You Can't Hide, B****!" [2]`,
            asset: "YouCanRunButYouCantHideB____2.mp3",
            scene: 2
        }].map(soundItem => {
            const player = soundItem?.asset ? new Audio(require('@/assets/sounds/' + soundItem.asset)) : undefined
            // Setup new soundItem object
            const newSoundItem = {
                ...soundItem,
                player,
                isPlaying: false
            }
            // Setup event listeners to update play state for new sound item
            player?.addEventListener('ended', () => {
                // console.log(`${soundItem.title} ended.`)
                newSoundItem.isPlaying = false
            })
            player?.addEventListener('play', () => {
                // console.log(`${soundItem.title} is playing.`)
                newSoundItem.isPlaying = true
            })
            player?.addEventListener('pause', () => {
                // console.log(`${soundItem.title} is paused.`)
                newSoundItem.isPlaying = false
            })
            return newSoundItem
        }))

        onMounted(() => {
            // Prime all sound player items
            soundItems.forEach(soundItem => {
                // This makes sure the sounds are pre-loaded
                soundItem?.player?.load()
            })
            soundItemsClean.forEach(soundItem => {
                // This makes sure the sounds are pre-loaded
                soundItem?.player?.load()
            })
        })

        const playSoundItem = (soundItem) => {
            if (soundItem?.player) {
                soundItem.player.play()
            }
        }

        const soundItemsForScene = (sceneId) => {
            const soundItemsList = props.isClean ? soundItemsClean : soundItems
            return soundItemsList.filter(aSound => aSound.scene === sceneId)
        }

        return { soundItemScenes, playSoundItem, soundItemsForScene }
    }
}
</script>

<style scoped>
.with-background {
    /* background: url('../assets/images/ScaryTerry.jpg'); */
    background-color: black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.v-card--link:before {
  background: none;
}
</style>
